import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa"; // Importing React Icons
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import footerlogo from "../images/footerlogo.png";

const Footer = () => {
  return (
    <footer className="bg-[#0c1032] text-white py-10 p-3">
      <div className="container grid grid-cols-1 gap-6 mx-auto md:grid-cols-5">
        {/* NM Creative Hub Logo and Description */}
        <div className="flex flex-col items-center col-span-1 md:items-start">
          <img
            src={footerlogo}
            alt="NM Creative Hub Logo"
            className="w-full mb-4"
          />
          <p className="mb-4 text-sm text-center md:text-left">
            Helping Brands Achieve Growth with Innovative Strategies and
            Targeted Solutions
          </p>
          <div className="flex space-x-4">
            <Link to="#" className="text-white">
              <FaFacebook className="w-6 h-6" />
            </Link>
            <Link to="#" className="text-white">
              <FaInstagram className="w-6 h-6" />
            </Link>
            <Link to="#" className="text-white">
              <FaLinkedin className="w-6 h-6" />
            </Link>
            <Link to="#" className="text-white">
              <FaWhatsapp className="w-6 h-6" />
            </Link>
          </div>
        </div>

        {/* Services */}
        <div className="col-span-1 ml-4">
          <h3 className="mb-4 text-2xl font-bold text-blue-300">Services</h3>
          <ul className="space-y-2">
            <li>
              <Link to="#" className="hover:underline">Social Media</Link>
            </li>
            <li>
              <Link to="#" className="hover:underline">SEO Optimization</Link>
            </li>
            <li>
              <Link to="#" className="hover:underline">One-Page SEO</Link>
            </li>
            <li>
              <Link to="#" className="hover:underline">Content Marketing</Link>
            </li>
            <li>
              <Link to="#" className="hover:underline">Email Marketing</Link>
            </li>
            <li>
              <Link to="#" className="hover:underline">Keyword Research</Link>
            </li>
          </ul>
        </div>

        {/* Useful Links */}
        <div className="col-span-1">
          <h3 className="mb-4 text-2xl font-bold text-blue-300">Useful Links</h3>
          <ul className="space-y-2">
            <li>
              <Link to="#" className="hover:underline">Career</Link>
            </li>
            <li>
              <Link to="#" className="hover:underline">Our Blogs</Link>
            </li>
            <li>
              <Link to="#" className="hover:underline">Our Portfolio</Link>
            </li>
          </ul>
        </div>

        {/* Contact Us */}
        <div className="col-span-1">
          <h3 className="mb-4 text-2xl font-bold text-blue-300">Contact Us</h3>
          <ul className="space-y-2">
            <li>
              <Link to="tel:+919992040807" className="hover:underline">
                +91-99920-40807
              </Link>
            </li>
            <li>Sirsa</li>
            <li>
              <Link
                to="https://mail.google.com/mail/?view=cm&fs=1&to=work.nmcreativehub@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                work.nmcreativehub@gmail.com
              </Link>
            </li>
          </ul>
        </div>

        {/* Newsletter */}
        <div className="col-span-1">
          <h3 className="mb-4 text-xl font-bold">Newsletter</h3>
          <p className="mb-4">Subscribe to our newsletter</p>
          <div className="flex flex-col">
            <div className="flex mb-2">
              <input
                type="email"
                placeholder="Email Address"
                className="w-full p-2 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button className="px-4 text-white transition duration-300 bg-blue-600 rounded-r-lg hover:bg-blue-700">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-10 border-t border-gray-600" />

      {/* Copyright */}
      <div className="mt-4 text-sm text-center">
        &copy; {new Date().getFullYear()} NM Creative Hub. All Rights Reserved.
        Designed and Developed by NM Creative Hub.
      </div>
    </footer>
  );
};

export default Footer;
