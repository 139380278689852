import React from "react";
import teamImage from "../images/teamImage.webp"; // Team image
import missionImage from "../images/missionImage.webp"; // Mission image
import visionImage from "../images/visionImage.webp"; // Vision image

function AboutUs() {
  return (
    <div className="bg-[#00032d] text-white min-h-screen py-16">
      <div className="container px-4 mx-auto">
        {/* Section: Who We Are */}
        <div className="mb-16 text-center">
          <h2 className="mb-6 text-5xl font-bold">About NM Creative Hub</h2>
          <p className="max-w-3xl mx-auto text-xl">
            NM Creative Hub is a forward-thinking digital marketing agency
            located in Haryana. We specialize in helping brands elevate their
            online presence by delivering data-driven strategies and creative
            solutions. With a passion for growth and innovation, we work
            tirelessly to help businesses succeed in the modern digital
            landscape.
          </p>
        </div>

        {/* Mission, Vision, and Values Section */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {/* Mission */}
          <div className="p-6 text-center text-gray-900 bg-gray-100 rounded-3xl">
            <img
              src={missionImage}
              alt="Mission"
              className="w-32 mx-auto mb-4 rounded-lg"
            />
            <h3 className="mb-4 text-3xl font-semibold text-blue-900">
              Our Mission
            </h3>
            <p className="text-lg">
              Our mission is to provide tailored digital marketing solutions
              that drive business growth. We are dedicated to creating impactful
              campaigns that deliver measurable results, foster client
              relationships, and enable brands to thrive in a competitive
              environment.
            </p>
          </div>

          {/* Vision */}
          <div className="p-6 text-center text-gray-900 bg-gray-100 rounded-3xl">
            <img
              src={visionImage}
              alt="Vision"
              className="w-32 mx-auto mb-4 rounded-lg"
            />
            <h3 className="mb-4 text-3xl font-semibold text-blue-900">
              Our Vision
            </h3>
            <p className="text-lg">
              Our vision is to become a global leader in digital marketing by
              empowering brands to build meaningful connections with their
              audiences. We strive to combine creative storytelling with
              data-driven insights to redefine what’s possible in the digital
              space.
            </p>
          </div>

          {/* Values */}
          <div className="p-6 text-center text-gray-900 bg-gray-100 rounded-3xl">
            <img
              src={teamImage}
              alt="Values"
              className="w-32 mx-auto mb-4 rounded-lg"
            />
            <h3 className="mb-4 text-3xl font-semibold text-blue-900">
              Our Values
            </h3>
            <p className="text-lg">
              Integrity, creativity, and collaboration are at the core of
              everything we do. We believe in fostering innovation, building
              trust with our clients, and delivering exceptional results with
              transparency and accountability.
            </p>
          </div>
        </div>

        {/* Our Approach Section */}
        <div className="mt-16 mb-16 text-center">
          <h3 className="mb-8 text-4xl font-bold">Our Approach</h3>

          <p className="max-w-4xl mx-auto mb-6 text-xl leading-relaxed">
            At{" "}
            <span className="font-semibold text-blue-500">NM Creative Hub</span>
            , we combine creativity with strategy to provide digital marketing
            solutions that are not only innovative but also precisely aligned
            with your business goals. Whether it’s{" "}
            <span className="font-semibold">SEO</span>,{" "}
            <span className="font-semibold">content marketing</span>,{" "}
            <span className="font-semibold">social media management</span>, or{" "}
            <span className="font-semibold">PPC campaigns</span>, we deliver
            services that are highly customized to your brand’s needs.
          </p>

          <p className="max-w-4xl mx-auto mb-6 text-xl leading-relaxed">
            Our approach is deeply rooted in{" "}
            <span className="font-semibold text-blue-500">
              data-driven insights
            </span>{" "}
            and measurable outcomes. We leverage real-time analytics and
            industry trends to ensure that every campaign is effective and
            delivers tangible results, while continuously optimizing performance
            to keep your brand competitive and visible.
          </p>

          <p className="max-w-4xl mx-auto mb-6 text-xl leading-relaxed">
            We pride ourselves on our{" "}
            <span className="font-semibold text-blue-500">
              collaborative partnerships
            </span>
            . Our client-first philosophy ensures that your input is at the
            heart of everything we do. Together, we build marketing strategies
            that not only meet but exceed your expectations, driving growth,
            increasing brand awareness, and creating lasting connections with
            your audience.
          </p>

          <p className="max-w-4xl mx-auto text-xl leading-relaxed">
            At NM Creative Hub, we believe in staying ahead of the curve. We
            continually push boundaries, explore emerging technologies, and
            embrace innovation to deliver cutting-edge solutions. Let us help
            you craft a digital marketing strategy that’s as dynamic and unique
            as your business.
          </p>
        </div>

        {/* Team Section */}
        <div className="mt-16">
          <h3 className="mb-12 text-4xl font-bold text-center">
            Meet Our Team
          </h3>
          <div className="flex flex-wrap justify-center gap-8">
            {/* Team Member 1 */}
            <div className="w-64 p-6 text-center text-gray-900 bg-white rounded-lg shadow-md">
              <img
                src={teamImage}
                alt="Team Member"
                className="w-32 mx-auto mb-4 rounded-full"
              />
              <h4 className="text-xl font-semibold">John Doe</h4>
              <p className="text-gray-600">CEO & Founder</p>
              <p className="mt-4 text-sm">
                With over 15 years of experience in the digital marketing
                industry, John leads NM Creative Hub with a vision for growth
                and innovation. His strategic thinking and leadership skills
                have driven success for numerous brands globally.
              </p>
            </div>

            {/* Team Member 2 */}
            <div className="w-64 p-6 text-center text-gray-900 bg-white rounded-lg shadow-md">
              <img
                src={teamImage}
                alt="Team Member"
                className="w-32 mx-auto mb-4 rounded-full"
              />
              <h4 className="text-xl font-semibold">Jane Smith</h4>
              <p className="text-gray-600">Marketing Director</p>
              <p className="mt-4 text-sm">
                Jane is a creative mastermind who brings brands to life with her
                marketing expertise. She has a keen eye for detail and is
                passionate about developing innovative campaigns that connect
                with audiences in meaningful ways.
              </p>
            </div>

            {/* Team Member 3 */}
            <div className="w-64 p-6 text-center text-gray-900 bg-white rounded-lg shadow-md">
              <img
                src={teamImage}
                alt="Team Member"
                className="w-32 mx-auto mb-4 rounded-full"
              />
              <h4 className="text-xl font-semibold">Alice Brown</h4>
              <p className="text-gray-600">Creative Strategist</p>
              <p className="mt-4 text-sm">
                Alice is the creative force behind our campaigns, blending art
                and strategy to deliver compelling content. Her passion for
                storytelling and creativity drives innovative solutions that
                captivate and engage.
              </p>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center">
          <h3 className="mb-4 text-4xl font-bold">Let’s Work Together</h3>
          <p className="max-w-xl mx-auto mb-6 text-xl">
            Are you ready to elevate your brand’s online presence? Contact us
            today to discuss how we can help you achieve your digital marketing
            goals.
          </p>
          <a
            href="/contact"
            className="px-6 py-3 font-semibold text-white bg-blue-900 rounded-full hover:bg-blue-800"
          >
            Get in Touch
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
